@use "sass:color";
//////////////////////////
//
// WHEN EDITING THIS FILE ALSO EDIT THE TINYMCE.STYLES.CSS!
//
//////////////////////////
.yuno__textfield__styling {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0.25rem;

		&:not(:first-child) {
			margin-top: 1.25rem;
		}

		~ p {
			&:first-of-type {
				@apply mt-1;
			}
		}

		~ h1,
		~ h2,
		~ h3,
		~ h4,
		~ h5,
		~ h6 {
			&:first-of-type {
				margin-top: 0.25rem;
			}
		}
	}

	h1 {
		font-size: var(--text-h1-font-size);
		font-weight: var(--text-h1-font-weight);
		font-family: var(--text-h1-font-family), sans-serif;
		color: var(--text-h1-font-color);
		line-height: 2rem;
		margin-bottom: 0.75rem;
	}

	h2 {
		font-size: var(--text-h2-font-size);
		font-weight: var(--text-h2-font-weight);
		font-family: var(--text-h2-font-family), sans-serif;
		color: var(--text-h2-font-color);
		line-height: 1.75rem;
	}

	h3 {
		font-size: var(--text-h3-font-size);
		font-weight: var(--text-h3-font-weight);
		font-family: var(--text-h3-font-family), sans-serif;
		color: var(--text-h3-font-color);
		line-height: 1.75rem;
	}

	h4 {
		font-size: var(--text-h4-font-size);
		font-weight: var(--text-h4-font-weight);
		font-family: var(--text-h4-font-family), sans-serif;
		color: var(--text-h4-font-color);
		line-height: 1.5rem;
	}

	h5 {
		font-size: var(--text-h5-font-size);
		font-weight: var(--text-h5-font-weight);
		font-family: var(--text-h5-font-family), sans-serif;
		color: var(--text-h5-font-color);
		line-height: 1.25rem;
	}

	h6 {
		font-size: var(--text-h6-font-size);
		font-weight: var(--text-h6-font-weight);
		font-family: var(--text-h6-font-family), sans-serif;
		color: var(--text-h6-font-color);
		line-height: 1rem;
	}

	p {
		font-size: var(--text-p-font-size);
		font-weight: var(--text-p-font-weight);
		font-family: var(--text-p-font-family), sans-serif;
		color: var(--text-p-font-color);
		@apply leading-6 first:mt-0;
	}

	&.default {
		h1 {
			@apply text-2xl font-semibold;
			margin-bottom: 0.75rem;
			color: unset;
			font-family: unset;
		}

		h2 {
			@apply text-xl font-semibold;
			color: unset;
			font-family: unset;
		}

		h3 {
			@apply text-lg font-semibold;
			color: unset;
			font-family: unset;
		}

		h4 {
			@apply text-base font-semibold;
			color: unset;
			font-family: unset;
		}

		h5 {
			@apply text-sm font-semibold;
			color: unset;
			font-family: unset;
		}

		h6 {
			@apply text-xs font-semibold;
			color: unset;
			font-family: unset;
		}

		p {
			@apply leading-6 first:mt-0;
			color: unset;
			font-family: unset;
			font-size: unset;
			font-weight: unset;
		}
	}

	p + p,
	ul + p {
		@apply mt-4;
	}

	a {
		color: #2b8fe6;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: color.adjust(#2b8fe6, $lightness: -10%);
		}

		&:visited {
			color: #4f46e5;

			&:hover {
				color: color.adjust(#4f46e5, $lightness: -10%);
			}
		}
	}

	blockquote {
		border-radius: 0;
		border-left: 5px solid #f9e11e;
		padding: 0 0 0 1.125rem;
		background-color: transparent;
		margin: 2rem 0;
		font-size: 1em;
		font-weight: 400;

		p {
			display: inline;
		}
	}

	ul,
	ol {
		margin-top: 1.25rem;
		padding-left: 2.5rem;
		list-style-type: none;
		list-style-position: outside;

		li {
			margin-bottom: 0;
			text-align: left;
		}
	}

	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;
	}

	.shadowbox {
		border-radius: 4px;
		cursor: pointer;
	}
}

yuno-text-image-button {
	&.yuno__textfield__styling {
		&:last-child {
			margin-bottom: 1.2rem;
		}
	}
}

// for all textfield-toggles after another textfield-toggle remove the margin-top
yuno-textfield-toggles {
	+ yuno-textfield-toggles {
		margin-top: 0; // !important;
	}
}

.participation-post-reaction {
	@apply relative border-gray-200 bg-gray-200 px-4 py-3;

	::ng-deep {
		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&:before {
		content: ' ';
		position: absolute;
		top: -0.875rem;
		left: 1.625rem;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0.875rem 0.875rem 0.875rem;
		border-color: transparent;
		border-bottom-color: inherit;
	}
}

.participation-post-answer {
	@apply relative border-gray-200 bg-gray-200 px-4 py-3;

	::ng-deep {
		* {
			font-size: 1rem !important;
			color: rgb(77, 91, 116) !important;
			font-family: 'Open Sans', sans-serif !important;
			font-feature-settings: normal !important;
			font-variation-settings: normal !important;
		}

		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&:before {
		content: ' ';
		position: absolute;
		top: -0.875rem;
		right: 1.625rem;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0.875rem 0.875rem 0.875rem;
		border-color: transparent;
		border-bottom-color: inherit;
	}
}
